import React from 'react'
import { GridItem } from '@which/seatbelt'
import { isProductHub } from '@which/shared'

import { useFeatureValue } from '@growthbook/growthbook-react'

import type { ImageWithSources, LatestNews as LatestNewsType } from '../../../../generated/frontend'
import { AffiliateDisclaimer } from '../../../../shared/components/AffiliateDisclaimer/AffiliateDisclaimer'
import { AssociatedArticles } from '../../../../shared/components/AssociatedArticles/AssociatedArticles'
import { Breadcrumb } from '../../../../shared/components/Breadcrumb/Breadcrumb'
import { HideOnArticleScroll } from '../../../../shared/components/HideOnScroll/HideOnArticleScroll'
import { LatestNewsCards } from '../../../../shared/components/LatestNewsCards/LatestNewsCards'
import { LocalFooter } from '../../../../shared/components/LocalFooter/LocalFooter'
import { PromoBlock } from '../../../../shared/components/PromoBlock/PromoBlock'
import { SharingOptions } from '../../../../shared/components/Sharing/SharingOptions'
import {
  componentTypeMoreOn,
  componentTypeRelatedArticles,
} from '../../../../shared/constants/ga-variables'
import type { ArticleReviewsPageData } from '../../article-page-types'
import { ArticleHero } from '../../components/ArticleHero/ArticleHero'
import { AuthorWrapper } from '../../components/Author/AuthorWrapper'
import { Header } from '../../components/Header/Header'
import { IntroParagraph } from '../../components/IntroParagraph/IntroParagraph'
import { JumpLinks } from '../../components/JumpLinks/JumpLinks'
import { RelatedProducts } from '../../components/RelatedProducts/RelatedProducts'
import { WhichConnectMessage } from '../../components/WhichConnectMessage/WhichConnectMessage'
import { WithHideOnArticleScroll } from '../../components/WithHideOnArticleScroll/WithHideOnArticleScroll'
import styles from './ArticleReviewsPage.module.scss'

export const getReviewsComponents = (data: ArticleReviewsPageData) => {
  const {
    articleConfig,
    authors,
    introParagraph,
    jumpLinks,
    sharingData,
    header,
    heroVideo,
    latestNews,
    heroImage,
    moreOnArticles,
    relatedArticles,
    relatedProducts,
    breadcrumb,
    optionalArticleData,
    meta: { taxonomyHierarchy },
  } = data

  const { category } = taxonomyHierarchy

  const handleShowMoreOnJumplinkClick = () => {
    const showMoreBtn = document.querySelector<HTMLButtonElement>(
      '[data-testid="show-more-button"]'
    )

    if (showMoreBtn) {
      showMoreBtn.click()
    }
  }

  return {
    AffiliateDisclaimer: () => (
      <AffiliateDisclaimer affiliateDisclaimer={articleConfig?.affiliateDisclaimer} />
    ),
    Breadcrumb: () => <Breadcrumb {...breadcrumb} />,
    WhichConnectMessage: () => (
      <WhichConnectMessage showMessage={articleConfig?.whichConnectMessage} />
    ),
    Authors: () => <AuthorWrapper authors={authors} />,
    IntroParagraph: () => <IntroParagraph text={introParagraph} />,
    JumpLinks: () => <JumpLinks handleOnClick={handleShowMoreOnJumplinkClick} links={jumpLinks} />,
    RelatedProducts: () => <RelatedProducts relatedProducts={relatedProducts} />,
    SharingOptionsHorizontal: () => (
      <HideOnArticleScroll targetElementClass="sharing-options-footer">
        <SharingOptions sharingData={sharingData} />
      </HideOnArticleScroll>
    ),
    SharingOptionsVertical: () => (
      <SharingOptions
        sharingData={sharingData}
        orientation="vertical"
        className="sharing-options-footer"
      />
    ),
    ArticleHeader: () => <Header {...header} />,
    LatestNews: () => (
      <LatestNewsCards
        name={latestNews?.name}
        articles={latestNews?.articles as LatestNewsType['articles']}
        viewAllData={{ text: 'View all news', href: '/news' }}
        whichId="latest-news-bottom-link-all"
      />
    ),
    MoreOnLinks: ({ className }) => {
      // TODO: We might want to do some of this logic in data-provider if implementing properly
      // Added for AB test WHC-812
      const productHubSidebarTest = useFeatureValue('whc-812-hub-sidebar', null)
      const productHubCategory = isProductHub(category)

      if (productHubCategory) {
        const productHubLink = `/l/${taxonomyHierarchy.subVertical.slug}/${category.slug}`

        if (productHubSidebarTest === 'Variation 1') {
          return WithHideOnArticleScroll(AssociatedArticles, {
            ...moreOnArticles,
            title: '',
            titleCategory: category.name.toLowerCase(),
            titleCategoryLink: productHubLink,
            className,
            componentType: componentTypeMoreOn,
          })
        } else if (productHubSidebarTest === 'Variation 2') {
          return WithHideOnArticleScroll(AssociatedArticles, {
            ...moreOnArticles,
            title: `More on ${category.name.toLowerCase()}`,
            titleCategory: category.name.toLowerCase(),
            titleCategoryLink: productHubLink,
            seeAllLink: true,
            className,
            componentType: componentTypeMoreOn,
          })
        }
      }

      return WithHideOnArticleScroll(AssociatedArticles, {
        ...moreOnArticles,
        className,
        componentType: componentTypeMoreOn,
      })
    },
    RelatedArticles: ({ className }) =>
      WithHideOnArticleScroll(AssociatedArticles, {
        ...relatedArticles,
        className,
        componentType: componentTypeRelatedArticles,
      }),
    ArticleHero: () => (
      <ArticleHero heroImage={heroImage as ImageWithSources} heroVideo={heroVideo} />
    ),
    LocalFooter: optionalArticleData?.components?.localFooter
      ? () => (
          <GridItem className={styles.localFooter} span={{ medium: 12, large: 12 }}>
            <LocalFooter data={optionalArticleData.components.localFooter}></LocalFooter>
          </GridItem>
        )
      : () => <></>,
    MoneyPromoBlock: optionalArticleData?.components?.moneyPromoBlock
      ? ({ className }) =>
          WithHideOnArticleScroll(PromoBlock, {
            className,
            ...optionalArticleData?.components?.moneyPromoBlock,
          })
      : () => <></>,
  }
}
